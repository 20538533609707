<template>
  <div class>
    <div class="content">
      <!-- <Product v-if="showProduct" :inWindow="false" :productId="selectedExhibit" :setSelectedUser="setSelectedUser" :toggleShowChat="toggleShowChat" :closeProduct="closeProduct"/> -->
      <div class="row row-no-padding align-items-center p-0 box">
        <div class="row m-0 notes-content">
          <button type="button" class="close" aria-label="Close" @click="closeNote()">
            <span aria-hidden="true" class="pull-right">
              <img src="@/assets/images/Icon-Close.svg" alt="" class="close--icon">
            </span>
          </button>
          <div class="col-md-4 left-side h-100">
            <div class="notes-container h-100">
              <div class="d-flex flex-row text-center search-container">
                <input
                  type="text"
                  class="flex-fill form-control mb-2 text-field"
                  :placeholder="$t('views.search')"
                  v-model="filter"
                />
                <span class="search-icon">
                  <i class="align-middle fas fa-search"></i>
                </span>
              </div>
              <button class="btn__download--notes" @click="exportNotes()">Merkliste exportieren</button>
              <div
                v-if="filteredExhibits.length > 0"
                class="m-0 p-0 cards-container d-flex flex-column"
              >
                <div
                  v-for="( exhibit, index ) in filteredExhibits"
                  :key="index"
                  :class="['exhibits', selectedExhibit.uuid == exhibit.uuid ? 'selected-exhibit' : 'not-selected-exhibit']"
                >
                  <div class="notes-info white-text" @click="updateselectedExhibit(exhibit, index)">
                    <p v-html="exhibit.title" class="m-0 p-0 card-title"></p>
                    <!-- <p class="m-0 p-0 short-text">{{ exhibit.short_text }}</p> -->
                  </div>
                  <div
                    :class="['row', 'row-no-padding', 'align-items-center', 'h-100', 'note-icon-container', selectedExhibit.uuid == exhibit.uuid ? 'selected-note-icon-container' : 'not-selected-note-icon-container']"
                    @click="deleteNote(exhibit, index)"
                  >
                    <i :class="['note-icon', 'fas fa-times-circle']"></i>
                  </div>
                </div>
              </div>
              <div v-else class="no_note no_note_group">
                <img class src="@/assets/images/Icon-no-notes.svg" />
                <p v-html="$t('views.notes.no_notes')"></p>
              </div>
            </div>
          </div>
          <div class="h-100 col-md-8 details">
            <NotesDetails
              v-if="selectedExhibit.uuid"
              :showNoteMessage="showNoteMessage"
              :setSelectedExhibit="setSelectedExhibit"
              :toggleShowProduct="toggleShowProduct"
              :setSelectedUser="setSelectedUser"
              :selectedExhibit="selectedExhibit"
              :toggleShowChat="toggleShowChat"
              :getNotes="getNotes"
              @delete-note="handleChildDeletion"
              class="h-100"
            ></NotesDetails>
            <div v-else class="no_note no_note_content">
              <img class src="@/assets/images/Icon-Notes-grey.svg" />
              <p v-html="$t('views.notes.no_selected')"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NotesDetails from './NotesDetails';
// import HaefeleButton from '@/components/shared/Button';

export default {
  name: 'Notes',
  components: {
    // HaefeleButton,
    NotesDetails
  },
  props: {
    toggleShowChat: Function,
    setSelectedUser: Function,
    closeProduct: Function,
    showProduct: Boolean,
    toggleShowProduct: Function,
    closeNote: Function,
    setSelectedNote: Function,
    selectedNote: String,
    selectedExhibit: Object,
    setSelectedExhibit: Function,
    showNoteMessage: Function
  },

  data() {
    return {
      filter: null,
      notes: [],
      exhibits: [],
      defaultIndex: ''
    };
  },
  watch: {},
  methods: {
    updateselectedExhibit(exhibit, index) {
      if (exhibit.uuid != this.selectedExhibit.uuid) {
        this.selectedExhibitId = exhibit.uuid;
        this.defaultIndex = index;
        this.setSelectedExhibit(exhibit);
      }
    },
    getExhibitions(notes) {
      this.exhibits = [];
      for (let i = 0; i < notes.length; i++) {
        this.$http.get('/exhibit?uuid=' + notes[i].exhibit_uuid).then(res => {
          this.exhibits.push(res.data);

          // if(this.selectedExhibit){
          //   this.selectedExhibit.uuid = this.selectedExhibit.uuid;
          // }
        });
      }
    },
    deleteNote(exhibit) {
      this.$http({ method: 'delete', url: '/note?uuid=' + exhibit.note_uuid })
        .then(() => {
          this.selectedExhibitId = '';
          this.getNotes();
        })
        .catch(err => console.log(err));
    },
    handleChildDeletion(event) {
      this.deleteNote(event);
    },
    getNotes() {
      this.notes = [];
      this.$http
        .get('/notes')
        .then(res => {
          this.notes = res.data;
          this.getExhibitions(res.data);
        })
        .catch();
    },
    async exportNotes() {
      console.log('Notizenexport gestartet');
      var newWindow = window.open('blank');
      let response = await this.$http.get('/download-notes', { responseType: 'arraybuffer'});
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      console.log(fileURL);
      //Open the URL on new Window
      newWindow.location = fileURL;
    }
  },
  computed: {
    filteredExhibits() {
      return this.filter
        ? this.exhibits.filter(
          exhibit =>
            exhibit.title.toLowerCase().includes(this.filter.toLowerCase()) ||
            exhibit.short_text
              .toLowerCase()
              .includes(this.filter.toLowerCase())
        )
        : this.exhibits;
    }
  },
  created() {
    this.getNotes();
  }
};
</script>

<style lang="scss" scoped>
hr {
  background-color: rgba(255, 255, 255, 0.5);
  width: 80%;
}

.no_note {
  color: grey;
  margin: auto auto;
  padding-top: 20%;

  img {
    width: 40px;
    margin-bottom: 15px;
  }

  &_content {
    padding-top: 17%;
    width: 70%;
    font-size: 1rem;
    text-align: center;
    align-self: center;
  }
  &_group {
    font-size: 1rem;
    text-align: center;
    align-self: center;

    img{
      width: 50px;
    }
  }
}

.close {
  position: absolute;
  font-size: 20px;
  right: 50px;
  top: 50px;
  z-index: 112;
  color: white;
  opacity: 1 !important;
  font-weight: 100;
  transform: translate(-50%, -50%);

  &--icon{
    width: 14px;
  }
}

.mobile-seperator {
  display: none;
}

.content {
  min-height: calc(100vh);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 119 !important;
}

.box {
  z-index: 112;
  position: fixed;
  bottom: 0;
  width: calc(100% - 80px) !important;
  margin-left: 40px !important;
}

.notes-content {
  background-color: #000000cc;
  padding-top: 35px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  height: 60vh;
  position: relative;
  width: 100%;
}

.details {
  max-height: 60vh;
}

.note-bottons-container {
  margin-top: 20px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  padding-left: 0 !important;
  padding-right: 15px !important;
}

.note-bottons-container i {
  padding-top: 5px;
}

.btn {
  background-color: white;
  font-size: 20px;
  border-radius: 0px;
  padding: 0 !important;

  &__download {
    &--notes {
      display: flex;
      justify-content: space-between !important;
      align-items: center;
      padding: 5px 11.25px !important;
      background-color: white;
      height: 38px !important;
      border-radius: 0;
      white-space: nowrap;
      font-weight: 400;
      color: black;
      width: 100%; 
      margin-bottom: 30px;

      img{
        max-width: 17px;
      }
    }
  }
}

.note-button {
  font-size: 17px;
  padding: 5px !important;
  width: 100%;
}

.note-button:hover {
  background-color: $secondaryColor;
}

.left-side {
  max-height: 60vh;
  // overflow-y: scroll;
  margin-top: 20px;
  padding-bottom: 80px;
  padding-right: 50px !important;
}

.notes-container {
  margin-top: 1px !important;
  margin-left: 20px !important;
  margin-right: -15px !important;
  padding-left: 0 !important;
  padding-right: 50px !important;
  overflow-y: scroll;
  scrollbar-color: $scrollbarHaefele;
}

.cards-container {
  // height: calc(100% - 100px);
  overflow-y: auto;
  scrollbar-color: $scrollbarHaefele;
  scrollbar-width: thin;
}

.notes-info {
  font-size: 16px;
  display: inline-block;
  width: 84%;
  font-weight: 400;
  white-space: pre-line;
}

.exhibits {
  margin-bottom: 20px !important;
  border: 1px solid;
  position: relative;
  overflow: hidden;
}

.card-title {
  margin-bottom: 10px !important ;
  margin-top: 10px !important ;
  padding-left: 10px !important;
}

.not-selected-exhibit {
  border-color: white;
  cursor: pointer;
}

.selected-exhibit {
  border-color: $secondaryColor !important;
  cursor: pointer;
}

.short-text {
  display: inline-block;
  width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.note-icon-container {
  width: 14%;
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
}

::placeholder {
  color: white;
}

.not-selected-note-icon-container {
  background-color: white;
}

.notes:hover {
  cursor: pointer;
  border-color: $secondaryColor;
}

.notes:hover .note-icon-container {
  background-color: $secondaryColor;
}

.selected-note-icon-container {
  background-color: $secondaryColor !important;
}

.note-icon {
  font-size: 22px;
  width: 100%;
  text-align: center;
}

.row-no-padding {
  margin-left: 0;
  margin-right: 0;
}

.search-container {
  margin-bottom: 30px;
  height: 34px;
  border-color: rgba(255, 255, 255, 0.7);
}

.search-container input {
  height: 25px;
}

.search-container .text-field {
  border-radius: 0%;
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 0 !important;
  height: 34px;
  color: white;
  font-size: 16px;
}

.search-icon {
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 17px;
  padding-top: 1px;
  width: 50px;
}

@media screen and (max-width: 760px) {
  .content {
    position: relative;
    padding-top: 20px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .box {
    position: relative;
    margin-bottom: 50px !important;
  }

  .mobile-seperator {
    display: block;
  }

  .note-details-container {
    min-height: 200px;
  }

  .note-heading {
    margin-top: 20px;
  }

  .columns-button {
    margin-top: 20px;
  }
}
</style>
